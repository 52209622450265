[id^=site] {

    .container {


        img {
            width: 95%;
            height: auto;
        }

        .description {
            white-space: pre-line
        }

        .specs {
            strong {
                margin-right: 15px;
            }

            .products {
                display: inline-flex;
                flex-flow: row wrap;
                align-items: center;

                figure {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin: 0 15px;
                    margin-left: 0;

                    img {
                        width: auto;
                        height: 20px;
                        max-width: 50px;
                    }

                    figcaption {
                        Font-Family: 'Roboto Condensed', Sans-Serif;
                        padding-left: .5rem;

                    }
                }
            }

        }
    }

}

@media only screen and (min-width: 1025px) {
    [id^=site] {

        .container {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-template-rows: repeat(4, minmax(0, auto));
            gap: 1rem 5%;

            .specs {
                grid-column: 1/5;
                grid-row: 1/4;
            }

            .iphone-picture {
                grid-column: 5/6;
                grid-row: 1/3;
            }

            .mac-picture {
                grid-column: 1/3;
                grid-row: 3/6;
            }

            .description {
                grid-column: 3/6;
                grid-row: 3/6;
            }

        }

        &:nth-child(even) {
            .container {
                @media only screen and (min-width: 767px) {
                    .specs {
                        grid-column: 2/6;
                    }

                    .iphone-picture {
                        grid-column: 1/2;
                    }

                    .mac-picture {
                        grid-column: 4/6;
                    }

                    .description {
                        grid-column: 1/4;
                    }

                }

            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    [id^=site] {
        .container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 3fr;
            padding: 5%;

            .specs {
                grid-column: 1/2;
                grid-row: 1/2;
            }

            .iphone-picture {
                display: none !important;
            }

            .mac-picture {
                grid-column: 1/2;
                grid-row: 2/3;
                max-width: 500px;
                align-self: center;
                justify-self: center;
            }

            .description {
                grid-column: 1/2;
                grid-row: 3/4;
            }

            .products {
                align-content: flex-start;

                figure {
                    margin-top: .5rem;
                }
            }

        }
    }
}