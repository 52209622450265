#products {

    h1 {
        text-align: center;
    }

    .product-group {
        margin: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 25px;
        border-radius: 25px;
        padding: 15px;

        h2 {}

        figure {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 16px;

            img {
                width: auto;
                height: 44px;
                max-width: 50px;

            }

            figcaption {
                Font-Family: 'Roboto Condensed', Sans-Serif;
                padding-left: 8px;

            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #products {
        figcaption {
            display: none;
        }
    }
}

@media (prefers-color-scheme: dark) {
    #products {
        .product-group {
            background-color: unset;
            border: outset medium var(--link-color);
        }

    }
}