* {
    box-sizing: border-box;
}
body{
    background-color: var(--bg-color);

}
#root {
    // position: relative;
    // height: 100vh;

    section {
        // min-width: 100vw;
        position: relative;

        .container {
            max-width: 1280px;
            margin:auto;
            padding: 2rem 5vw;
        }
    }
}

@media only screen and (max-width: 767px) {
    #root {

        section {
            position: relative;
            .container {
                padding: 1rem 5vw;
            }
        }
    }
    }

// @media only screen and (min-height: 600px) {
//     #root {
//         scroll-snap-type: y mandatory;
//         overflow-y: scroll;

//         section {
//             scroll-snap-align: start;
//             min-height: 100vh;
//         }
//     }

// }