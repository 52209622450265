@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap');

h1,h2,h3,h4,h5,h6{
    Font-Family: 'Roboto Condensed', Sans-Serif;
    color: var(--header-color);
    ;
}

a{
    color: var(--link-color);
}

p, span, li, figcaption, strong {
    Font-Family: 'Cabin', Sans-Serif;
    Font-Size: 16px;
    color: var(--font-color);
}

h1 {
    font-size: 3rem;
}
