#presentations {
    .container {
        .presentation-list {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;

            &>div {
                background-color: rgba(255, 255, 255, 0.5);
                margin: 10px;
                border-radius: 25px;
                padding: 5px 25px;
                flex: 1 0 300px;
                gap: 100px;

                h2 {
                    min-height: 60px;
                    border-bottom: medium inset var(--bg-color);
                    ;
                }
            }

        }
    }
}

@media (prefers-color-scheme: dark) {
    #presentations {
        .container {
            .presentation-list {
                &>div {
                    background-color: unset;
                    border: outset medium var(--link-color);
                }

            }
        }
    }
}