//https://www.canva.com/learn/100-color-combinations/
// Subdued & Professional
// :root {
//     --bg-color: #90AFC5;
//     --header-color: #336b87;
//     --font-color: #2A3132;
//     --link-color: #763626;
//   }


//   :root {
//     --font-color: #04202C;
//     --header-color: #304040;
//     --bg-color: #c9d1c8;
//     --link-color: #5B7065;
//   }

//Cool Blues  
:root {
  --font-color: #003B46;
  --header-color: #07575B;
  --bg-color: #C4DFE6;
  --link-color: #39656A; //formerly #66A5AD
}

// var(--font-color);

@media (prefers-color-scheme: dark) {
  :root {
    --font-color: #C4DFE6;
    --header-color: #0F9299;
    --bg-color: #003B46;
    --link-color: #67A8AD; //formerly #66A5AD
  }
}