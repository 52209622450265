#aboutMe {
    ul {
        list-style-type: none;
        font-weight: bold;
    }

    .left-side {
        display: grid;

        .greeting {
            align-self: center;
            justify-self: center;

            p {
                font-size: 2rem;
            }

            em {
                color: var(--bg-color);
                ;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--font-color);
                ;
            }
        }

    }

    .right-side {
        background-color: var(--font-color);
        grid-column: 3/6;
        grid-row: 1;
        ul{
            li{
                color: var(--bg-color);
            }
        }
    }

    .profile-pic {
        grid-column: 2/4;
        grid-row: 1;
        height: auto;
        align-self: center;
        padding: 25px;
        background: var(--bg-color);
        background: linear-gradient(90deg, var(--font-color) 0%, var(--font-color) 50%, var(--bg-color) 50%, var(--bg-color) 100%);

        img {
            width: 100%;
            height: auto;
        }
    }

    .stats {
        li {
            font-size: 25px;
            Font-Family: 'Roboto Condensed', Sans-Serif;
        }
    }

}

@media only screen and (min-width: 768px) {
    #aboutMe {
        display: grid;
        grid-template-columns: 3fr 250px 250px 1fr;
        grid-template-rows: 1fr;
        min-height: 800px;
        .left-side{
            grid-column: 1/3;
            grid-row: 1;
            padding-right:250px;
            padding-left: 2rem;
            }
    }
}

@media only screen and (max-width: 767px) {
    #aboutMe {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding:5%;
        .right-side {
            display:none;
    
        }
    
        .profile-pic {
            display: none;
        }
    
    }
}